import React from "react";

import bg from './images/background.png';
import zlurpee from './images/zlurpee.png';
import zogz from './images/zogz.png';

import love7 from "./music/love7.mp3";
import silence from "./music/silence.mp3";

import dextools from './images/icons/dextools1.webp';
import twitter from './images/icons/twitter2.webp';
import telegram from './images/icons/tg.webp';
import etherscan from './images/icons/etherscan.webp';
import uniswap from './images/icons/uniswap2.webp';


import './App.css';

import './fonts/pixel/Uni0553.woff';
import './fonts/pixel/Uni0553.woff2';
import './fonts/pixel/Uni0553.ttf';

import './styles/theme.css'
import './styles/mobile.css'


function App() {


  return (
    <div className="App">
      <div className="image-wrapper">
        <img src={bg} className="big-bg" />
      </div>
      <div className="socials">
        <a target={'_blank'} href='https://twitter.com/ZlurpeeERC20'><img src={twitter} /></a>

        <a target={'_blank'} href='https://t.me/zlurpeeportal'><img src={telegram} /></a>
        <a target={'_blank'} href='https://etherscan.io/address/0x6db32ba9c42117837c269ae35b87db2f197bb861'><img src={etherscan} /></a>
        <a target={'_blank'} href='https://www.dextools.io/app/en/ether/pair-explorer/0xfa2711fe145d6657d0743e9a7810c72010155d65'><img src={dextools} /></a>
        <a target={'_blank'} href='https://app.uniswap.org/#/swap?outputCurrency=0x6db32ba9c42117837c269ae35b87db2f197bb861&chain=ethereum'><img src={uniswap} /></a>
      </div>



      <div className="intro">

        <div className="container">
          <img src={zlurpee} className="zlurpee" />
          <img src={zogz} className="zogz" />
          <div className="content-wrapper">
            <h1>Zlurpee</h1>
            <h3>Zlurp Z dips like no other Pepe.</h3>


            <audio src={love7} controls autoplay />


            <p><b> Tokenomics</b> <br /> Supply: 1,000,000,000 $ZLURP<br />2% buy Tax / 0% sell Tax</p>

            <p>Introducing Zlurpee - the memecoin sensation born from the deepest corners of the internet. Much like the adored Pepe memes, Zlurpee has arrived to dominate the online realm, boasting unmatched meme potential and irresistible zlurping.</p>

            <p><b> Zlurp the dips, Rekt the jeets. That Simple.</b> </p>

            <p>86% of the tokens were used for the liquidity pool, LP tokens were locked for 69 years, and contract is renounced. The remaining 14% of the supply was airdropped to a secret council that have zlurped with us for years. </p>

            <p><b>Zlurpee is built for the people and owned by the community.</b></p>


          </div>
        </div>
      </div>



      <footer>
        <p>Zlurpee coin has no association with Matt Furie or his creation Pepe the Frog. <br />This token is simply paying homage to a meme we all love and recognize.</p>

        <p>Zlurpee is a meme coin with no intrinsic value or expectation of financial return. <br />There is no formal team or roadmap. the coin is completely useless and for entertainment purposes only.</p>
        <strong>Zlurp the Dip!</strong>
      </footer>
    </div >

  );
}


export default App;